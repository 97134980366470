@font-face {
  font-family: "LindeDaxLigWeb";
  src: url(base/linde_dax_web/WOFF/Fonts/LindeDaxLigWeb.woff);
}

@font-face {
  font-family: "LindeDaxMedWeb";
  src: url(base/linde_dax_web/WOFF/Fonts/LindeDaxMedWeb.woff);
}
@font-face {
  font-family: "LindeDaxRegWeb";
  src: url(base/linde_dax_web/WOFF/Fonts/LindeDaxRegWeb.woff);
}
@font-face {
  font-family: "LindeDaxSemiLigWeb";
  src: url(base/linde_dax_web/WOFF/Fonts/LindeDaxSemiLigWeb.woff);
}
/***/
@font-face {
  font-family: "LindeDaxLigWeb-eot";
  src: url(base/linde_dax_web/EOT/Fonts/LindeDaxLigWeb.eot);
}
@font-face {
  font-family: "LindeDaxMedWeb-eot";
  src: url(base/linde_dax_web/EOT/Fonts/LindeDaxMedWeb.eot);
}
@font-face {
  font-family: "LindeDaxRegWeb-eot";
  src: url(base/linde_dax_web/EOT/Fonts/LindeDaxRegWeb.eot);
}
@font-face {
  font-family: "LindeDaxSemiLigWeb-eot";
  src: url(base/linde_dax_web/EOT/Fonts/LindeDaxSemiLigWeb.eot);
}
/***/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

body {
  margin: 0;
  font-family: "LindeDaxRegWeb", 'Roboto', sans-serif;
  /* font-family: "Roboto", 'Roboto', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececec;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.button{
  cursor: pointer;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #318300;
  border-color: #318300 transparent #318300 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

input[type='checkbox'] {
  accent-color: #318300;
}

.hoverButton:hover {
  color:#318300;
}


.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 12px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: -3px;
  left: 1px;
  width: 18px;
  height: 18px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #128D15;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #00a0e1;
  -moz-box-shadow: 0px 0px 3px 2px #00a0e1;
  box-shadow: 0px 0px 2px 3px #00a0e1;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #00a0e1;
  -moz-box-shadow: 0px 0px 5px 5px #00a0e1;
  box-shadow: 0px 0px 5px 5px #00a0e1;
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border:none;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #becd00;
  content: '';
  display: inline-block;
  visibility: visible;
  border: none;
}

.react-calendar__tile--active {
  background: #128D15 !important;
}
.react-calendar__tile--now {
  background: none !important;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
  background: #318300!important;
  color: #fff;
}
  /******************Style******************/
  select.combobox-custom{
    box-shadow: none!important;
    border: 2px solid #b6b6b6;
    border-radius: 8px;
    text-indent: 10px;
  }
  /*Inputs*/
  .label-input{
    display: flex;
    font-size: 14px;
    font-weight: 500;
  }

  /*Modal*/
  .subtext{
    font-size: 14px;
  }
  .order-text{
    font-size: 18px;
    color: black;
    font-weight: 700;
  }

  /*Breadcrumb*/
  .breadcrumb{
    color: black!important;
    padding: 12px 20px!important;
    background: #e1e1e1;
  }
  .breadcrumb-value{
    color: black!important;
    font-size: 14px!important;
    font-weight: 500;
  }
  .breadcrumb-selected{
    color: black!important;
    font-size: 14px!important;
  }

  /*Painel*/
  .panelbox-header{
    display: flex;
    /* border-bottom: solid 2px #318300; */
  }
  .panelbox{
    flex: 1;
    align-items: baseline;
    display: flex;

  }
  .paneltitle{
    font-size: 16px;
    color: black;
    font-weight: bold;
  }
  .panelsubtitle{
    font-size: 14px;
    color: black;
    font-weight: 500;
  }

/*Table*/
table{
  cursor: default;
}
  .table-style tr th{
    color: white!important;
    font-size: 13px;
    background: #767676;
    padding: 5px!important;
  }
  tr td .table-style tr th{
    color: black!important;
    font-size: 13px;
    background: #f7f7f7;
    padding: 5px!important;
    border-bottom: dashed 1px #000;
  }
  .table-style tr td{
    font-size: 14px;
  }
  .table-expand{
    color: black!important;
  }
  .box-expand-panel{
    background: #f6f6f6;
    flex: 1;
    margin: 2px;
    padding: 10px;
    border-radius: 5px;
    /* max-height: 300px; */
  }
  .img-equip{
    text-align: center;
  }
  .image-equipment img{
    height: 100%;
  }
  .equipment-name{
    color: black;
    font-size: 18px;
    font-weight: 600;
  }
  .description{
    color: black;
    font-size: 14px;
    background: #e9e9e9;
    margin: 5px;
    padding: 3px 12px;
    border-radius: 15px;
  }
  .equi-tiles-description{
    font-size: 14px;
    padding: 10px 0;
  }
/*Icons*/
  .icon-class{
    margin-right: 10px;
  }
  /* .equip-list{
    background: #e9e9e9;
    border-radius: 10px;
    padding: 0px 9px;
    margin-bottom: 5px;
} */

.tile-hover:hover{
  background: linear-gradient(0deg, rgba(143,143,143,1) 0%, rgba(228,228,228,1) 0%, rgba(255,255,255,1) 23%);
}
/*Menu*/
.menu-panel{
  color: #000000;
  font-weight: bold;
}
.link-custom{
  display: flex;
}
.link-custom div{
  float: left;
  margin-right: 10px;
}

/*ConfigurationPanel*/

/*Filter Chips*/
.chips-panel{
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.filter-chips{
  font-size: 16px;
  display: flex;
  background: #e2e2e2;
  width: auto;
  border-radius: 20px;
  padding: 3px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #000;
  align-items: center;
}
.chip-value{
  font-weight: bold;
}
.chip-del{
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  display: flex;
}
h4{
  padding-left: 5px;
  margin: 5px 0;
  font-weight: 500;
}

/****/
.image-slider img{
  border-radius: 15px!important;
}
/* .image-gallery{
  padding: 14px!important;
} */
.image-gallery-content .image-gallery-slide .image-gallery-image{
  max-height: 200px;
  height: 200px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image, .image-gallery-thumbnail-image, .image-gallery-thumbnail{
  vertical-align: middle!important;
  width: 100%!important;
  line-height: 0!important;
  width: 50px!important;
  border-radius: 6px!important;
}
.image-gallery-left-nav, .image-gallery-right-nav{
  padding: 20px 10px!important;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
  height: 40px!important;
  width: 30px!important;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{
  outline: none!important;
  border: solid 3px #2a7a00!important;
  width: 60px!important;
  border-radius: 10px!important;
  background: #dfdfdf!important;
}

select{
  padding-left: 3px!important;
  text-indent: 0px!important;
}
.new-order{
  background-color: #084d01;
    border-radius: 12px;
    color: #fff;
    padding: 5px 15px!important;
    width: 90%;
}
.new-order:hover{
  background-color: #2a580e!important;
    border-radius: 12px;
    color: #fff!important;
    padding: 5px 15px!important;
    width: 90%;
}
/****************************************************** WEB */
.react-calendar{
  width: 250px!important;
  border: none!important;
  margin: 0 auto;
}
.custom-calendar{
  text-align: center!important;
}
.react-calendar__year-view .react-calendar__tile{
  padding: 10px 0!important;
}
.react-calendar__navigation{
  width: 250px!important;
}
.content-stage-default{
  height: calc(100vh - 80px)!important;
}
.content-stage-disclaimer{
  height: calc(100vh - 160px)!important;
}
/* Filters Checkbox */
.filter-content{
  display: flex;
  border-bottom: dotted 1px #9a9a9a;
  flex-direction: column;
  padding-bottom: 10px;
  margin-top: -5px;
}
.filter-checks{
  display: flex;
}
.filters-options{
  margin-Right:10px;
  margin-Bottom:5px;
  font-Weight:bold;
  color:black
}
/* Default Finish Order */
.default-finish-order{
  display:flex;
  align-Items:stretch;
}
.delivery-configuration{
  flex:1;
  margin-Left:15px;
  margin-Right:15px;
  padding:20px;
  box-Sizing:border-box;
  display:flex;
}
/* Pagination */
.pagination{
  display:flex;
  justify-Content: center;
}
.pagination-content{
  box-Sizing:border-box;
  padding:10px;
  display:flex;
  justify-Content:center;
  align-Items:center;
}
.selection span{
  line-Height:40px;
  margin-Right:10px;
}
.numbers{
  display: flex;
  align-items: center;
}
/*Tiles*/
.tiles-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.tiles-content li{
  list-style: none;
  padding: 15px;
  width: 33%;
  box-sizing: border-box;
}
/*Filter*/
.filter-title{
  box-Sizing:border-box;
  margin-right: 10px;
}
.filter-custom{
  padding-bottom: 10px;
  display:flex; 
  justify-Content:flex-Start;
}
.filter-inputs{
  margin:0 10px 0 0;
  align-items: baseline;
}
.filter-buttons{
  display: flex;
}
.rounded-button{
  width: 100%;
  margin-right: 10px;
}
.secondary-button{
  width: 100%;
}
.custom-recharge-button-mob{
  display: none;
}
.custom-recharge-button-web{
  display: block;
}
.titulo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.message-event{
  background: #f4f5d0;
  padding: 20px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.item-body-inside-expandle-responsive{

}
.item-body-inside-responsive{

}
.item-body-responsive{

}
.item-title-responsive{

}
.mobile-fix{
  display: flex;
}
.error-message{
  display: flex;
  background: #f1dbdb;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  border-radius: 20px;
}
h3.erro-text{
  color: #bd1212;
  line-height: 32px;
  display: flex;
  align-items: center;
  margin: 10px;
  flex: 1;
  justify-content: center;
}
/****************************************************** Small Screens   */
@media screen and (min-width: 600px) and (max-width: 1366px) {
  .report-mobile-content{

  }
  .report-mobile{

  }
}
/****************************************************** MOBILE   */
@media screen and (max-width: 600px) {
  .mobile-fix{
    display: block;
  }
  .pop-up-assistance{
    position: relative;
    top: 40%;
    padding: 20px;
  }
  .panel-mobile{
    padding: 10px!important;
  }
  .modal-mobile{
    padding: 20px;
    top: 30%;
    position: relative;
  }
  .titulo{
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .titulo button{
    width: 100%;
  }
  .recharge-title{
    display: block!important;
  }
  .recharge-title h3{
    margin-bottom: 5px!important;
  }
  .recharge-input{
    width: 100%!important;
  }
  .custom-recharge-button-mob{
    display: block;
  }
  .custom-recharge-button-mob button{
    width: 100%!important;
  }
  .custom-recharge-button-web{
    display: none;
  }
  .react-calendar{
    width: 250px!important;
    border: none!important;
  }
  .custom-calendar{
    position: relative!important;
    text-align: center!important;
  }
  .react-calendar__year-view .react-calendar__tile{
    padding: 10px 0!important;
  }
  .react-calendar__navigation{
    width: 250px!important;
  }
  .tiles-mobile{
    margin: 8px!important;
  }
  .panelbox-header{
    display: block;
  }
  .panelbox{
    width: 100%;
    margin-bottom: 10px;
  }
  .panelbox-action{
    /* flex: 1 1 0%; */
    text-align: right;
    padding-bottom: 0!important;
    display: block;
    justify-content: end;
  }
  .panelbox-action button{
    width: 100%!important;
  }
  .panelbox-action a{
    width: 100%!important;
  }
  .panelbox-action a button{
    width: 100%!important;
  }
  .paneltitle {
    font-size: 16px;
    font-weight: bold;
  }
  .filter-content{
    display: block;
    border-bottom: dotted 1px #9a9a9a;
    padding-bottom: 10px;
    flex-direction: column;
  }
  .filter-inputs{
    display: block!important;
  }
  /* Filters Checkbox */
  .filter-checks{
    display: flex;
    flex-wrap: wrap;
  }
  .filters-options{
    margin-Right:10px;
    margin-Bottom:5px;
    font-Weight:bold;
    color:black;
    width: 47%;
  }
  /* Image Slider */
  .image-slider{
    width: 100%;
    margin: 0 0 20px 0;
    max-width: unset!important;
  }
  /* Default Finish Order */
  .content-main{
    height: auto!important;
  }
  .right-panels{
    display: flex;
    flex-direction: column;
  }
  .delivery-description{
    order: 2;
  }
  .delivery-details{
    order: 1;
    margin-bottom: 10px;
  }
  .default-finish-order{
    display:block;
    align-Items:stretch;
  }
  .delivery-configuration{
    margin:0 0 10px 0; 
    padding:20px;
  }
  /* Pagination */
  .pagination{
    display:flex;
    justify-Content: center;
  }
  .pagination-content{
    box-Sizing:border-box;
    padding:10px;
    display:block;
    justify-Content:center;
    align-Items:center;
  }
  .selection{
    text-align: center;
  }
  .selection span{
    line-Height:40px;
    margin-Right:10px;
  }
  .numbers{
    display: flex;
    align-items: center; 
  }
  .numbers svg{
    font-size: 32px;
  }
  .numbers button{
    padding: 7px;
  }
  /*Tiles*/
  .tiles-content{
    display: block;
    flex-wrap: wrap;
    justify-content: left;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .tiles-content li{
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    list-style: none;
  }
  /*Filter*/
	.filter-custom {
		display: block;
	}
	.filter-inputs{
    width: 100%;
    margin-Left:0;
    margin-Top:0;
  }
  .filter-inputs select, .filter-inputs input{
    width: 100%;
    margin-bottom: 8px;
  }
  .filter-buttons{
    display: flex;
    justify-content: space-between;
  }
  .rounded-button{
    width: 100%;
    display: flex;
    justify-content: end;
    order: 2;
  }
  .secondary-button{
    width: 100%;
    order: 1;
  }
}

/****************************************************** big Scren   */
@media screen and (min-width: 1440px) {
.tiles-content li{
  list-style: none;
  padding: 15px;
  width: 25%;
  box-sizing: border-box;
}
}
/****************************************************** Mid Scren   */
@media screen and (min-width: 1440px) {
  .tiles-content li{
    list-style: none;
    padding: 15px;
    width: 25%;
    box-sizing: border-box;
  }
  }